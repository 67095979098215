import { gsap, Power2, TimelineLite } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
import parallax from './parallax';
import LazyLoad from "vanilla-lazyload";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import textAppear from './text-appear';
gsap.registerPlugin(ScrollTrigger);

require('intersection-observer');
import Lenis from '@studio-freight/lenis'


gsap.registerPlugin(ScrollToPlugin);

let _root;
let _allImages = 0;
let imagesLoaded = require('imagesLoaded');
let _tlProgress;
let _tweenInc;
let _preloaderProgress;
let _preloaderBar;
let lenis;
let _textAppear;

class App {
    static start() {
        return new App()
    }

    constructor() {
        _root = this;

        this.startIntersectionObserver();
        

        Promise
        .all([
            App.domReady(),
        ])
        .then(_root.init.bind(_root))

    }

    static domReady() {
        return new Promise(resolve => {
            document.addEventListener('DOMContentLoaded', resolve);
            let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });
            _allImages = _imagesLoaded.images.length;
            _imagesLoaded.on('always', _root.onAlways);
            _imagesLoaded.on('progress', _root.onProgress);

        })
    }

    onAlways(instance) {
        _root.animationStart();
    }

    animationStart() {


        const _preloader = document.getElementById('preloader');
        
        _tlProgress = new TimelineLite({ delay: 1 });
        
        if ('undefined' != typeof _preloader && null != _preloader) {
            _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
            _tlProgress.to(_preloader, 0.5, { autoAlpha: 0 });

            _preloaderProgress = _preloader.querySelector('.preloader-progress');
            if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
                _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
                _tlProgress.to(_preloaderProgress, 0.5, { autoAlpha: 0 });
            }
            _preloaderBar = _preloader.querySelector('.preloader-bar');
        }
        
    }

    startLenis() {
        lenis = new Lenis({
            lerp: 0.2,
            smooth: true
        });

        lenis.on('scroll', () => ScrollTrigger.update());

        const scrollFn = (time) => {
            lenis.raf(time);
            requestAnimationFrame(scrollFn);
        };
        
        requestAnimationFrame(scrollFn);
    }

    startIntersectionObserver() {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3,
        };
        const observer = new IntersectionObserver(_root.observerCallback, observerOptions);

        document.querySelectorAll('.el-anim').forEach((el) => observer.observe(el));
        document.querySelectorAll('.el-anim-b').forEach((el) => observer.observe(el));

    }

    observerCallback(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('loaded');
            }
        });
    }

    progressUpdate() {
        let _width = Math.round(_tweenInc.progress() * 100);
        if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
            _preloaderProgress.textContent = _width + '%';
        }
    }

    onProgress(instance, image) {
        if (image.isLoaded) {
            _countLoadedImages++;
        }

        _tweenInc = gsap.to(_preloaderBar, {
            duration: 0.5,
            width: "100%",
            onUpdate: _root.progressUpdate,
            ease: gsap.Linear
        });
    }

    static showPage() {
        
        _root.startLenis();

        new parallax();

        new LazyLoad({
            elements_selector: '.lazy',
            use_native: true,
            callback_loaded: _root.callBackImgLoaded
        });

        _textAppear = new textAppear();

        _textAppear.init();

        const element = document.querySelectorAll('.scroll-page-link');

        if ('undefined' != typeof element && null != element) {
            element.forEach(function(item) {
                _root.addEvent(item, 'click', _root.onCkickEvent);
            });
        }

    }

    onCkickEvent(el) {
        const _link = el.currentTarget.getAttribute('href');
        // const _anchor = document.querySelector(_link);
        // let scrollTop = 0;

        // if ('undefined' != typeof _anchor && null != _anchor) {
        //     scrollTop = _anchor.getBoundingClientRect().top + window.scrollY;

        // }


        lenis.scrollTo(_link);

        // gsap.to(window, {
        //     duration: 0,
        //     scrollTo: { y: scrollTop },
        //     ease: Power2.easeOut
        // });

        el.preventDefault();
    }

    callBackImgLoaded(trg) {
        trg.parentElement.classList.contains('img-loaded') ? trg.parentElement.classList.remove('img-loaded') : trg.parentElement.classList.add('img-loaded');
    }

    init() {
        App.showPage();
    }

    onCompleteAfter() {
        _menu.refresh();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}

App.start();