// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

const calcWinsize = () => {
    return { width: window.innerWidth, height: window.innerHeight };
};

// Gets the mouse position
const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    //if (e.pageX || e.pageY) {
    posx = e.clientX;
    posy = e.clientY;
    // }
    // else if (e.clientX || e.clientY) {
    //     posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
    //     posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
    // }

    return { x: posx, y: posy };
};

const wrapLines = (elems, wrapType, wrapClass) => {
    elems.forEach(char => {
        // add a wrap for every char (overflow hidden)
        const wrapEl = document.createElement(wrapType);
        wrapEl.classList = wrapClass;
        char.parentNode.appendChild(wrapEl);
        wrapEl.appendChild(char);
    });
}

export { map, lerp, calcWinsize, getMousePos, wrapLines };