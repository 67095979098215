import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let _root = this;

export default class Parallax {
    constructor() {
        _root = this;
        this.refresh();
    }

    refresh() {
        this.setSize();
    }

    setSize() {
        // if (window.innerWidth >= 992) {
        //     //this.startMoveElements();
        //     this.startMoveBgParallax();
        // }


        this.startMoveBgParallax();
        //this.startBgColor();
    }


    startMoveElements() {

        let element = document.querySelectorAll('.parallax-item');

        if ('undefined' != typeof element && null != element) {

            const tl = gsap.timeline({
                scrollTrigger: {
                    scrub: true
                }
            });

            gsap.utils.toArray(".parallax-item").forEach(layer => {
                const depth = layer.dataset.depth;
                const movement = -(layer.offsetHeight * depth);

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: layer.parentElement,
                        scrub: true
                    }
                });

                tl.to(
                    layer, {
                        y: movement,
                        ease: "none"
                    }, 0);

                if (layer.dataset.rotate) {
                    tl.to(
                        layer.querySelector('path'), {
                            rotation: layer.dataset.rotate,
                            scale: 0.8,
                            transformOrigin: "center center",
                            ease: "none"
                        }, 0);
                }
            });

        }

    }

    startMoveBgParallax() {

        let element = document.querySelectorAll('.parallax-img__container');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-img__container .parallax-img__img").forEach(section => {
                const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section.parentElement,
                        scrub: true
                    }
                });

                //tl.set(section, { scale: 1.5 })

                let direction = -heightDiff;

                if (section.dataset.inverted) {
                    direction = -(heightDiff/2);
                } 

                tl.to(
                    section, {
                        y: direction,
                        //scale: 1,
                        ease: "none"
                    }, 0);
            });
        }
    }

}